import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import {Navbar, Nav, NavItem} from 'react-bootstrap';
import Container from 'react-bootstrap/Container';

import Home from './Home.js';
import JoinWaitingRoom from './JoinWaitingRoom.js';
import WaitingRoom from './WaitingRoom.js';

import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <div>
    <Router>
      <Container>
        <div id="app" style={{height:'100%'}}>
          <br/>
          <Switch>
            <Route path="/waitingroom" component={WaitingRoom} />
          </Switch>
          <Switch>
            <Route path="/joinroom" component={JoinWaitingRoom} />
          </Switch>
          <Switch>
            <Route path="/" exact component={Home} />
          </Switch>
          <br/><br/><br/><br/><br/>
          </div>
        </Container>
    </Router>
    </div>
  );
}

export default App;
