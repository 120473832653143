import * as constants from './Constants.js';
import io from 'socket.io-client';

import React from 'react';
import Button from 'react-bootstrap/Button'
import Form from "react-bootstrap/Form";

class WaitingRoom extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      joinGameId: '',
      playerName: 'Player',
      isLeader: false,
      players: []
    };

    // In React, we "bind" class functions to the class itself. You need to do
    // this when you add new functions but otherwise you can ignore this
    // section.
    this.onStartGame = this.onStartGame.bind(this);
    this.onUpdatePlayers = this.onUpdatePlayers.bind(this);
    this.onGameMetadataLoad = this.onGameMetadataLoad.bind(this);
    this.onLeaderUpdate = this.onLeaderUpdate.bind(this);
    this.onNameChange = this.onNameChange.bind(this);
    this.startGame = this.startGame.bind(this);

    const params = new URLSearchParams(this.props.location.search);
    this.joinGameId = params.get('joinGameId') === null ? '' : params.get('joinGameId');
    this.setState({
      joinGameId: this.joinGameId
    });
  }

  componentDidMount() {
    this.initSocketConnection();
    this.clientSocket.emit('update_player_name', {playerName: this.state.playerName})
  }

  initSocketConnection() {
    const handShake = 'joinGameId=' + this.joinGameId;
    this.clientSocket = io(constants.SOCKET_ADDRESS_WAITING_ROOM, {
      forceNew: true,
      query: handShake
    });
    this.clientSocket.on('disconnect', (reason) => {
      if (reason === 'io server disconnect') {
        this.clientSocket = io(constants.SOCKET_ADDRESS_WAITING_ROOM, {
          forceNew: true,
          query: handShake
        });
      }
    });

    this.clientSocket.on('start', this.onStartGame);
    this.clientSocket.on('update_players', this.onUpdatePlayers);
    this.clientSocket.on('game_metadata', this.onGameMetadataLoad);
    this.clientSocket.on('leader', this.onLeaderUpdate);
  }

  onStartGame(data) {
    window.location.href = constants.GAME_WEB_ADDRESS + '?gameId=' + data.gameId + '&playerId=' + data.playerId + '&playerName=' + this.state.playerName + '&teamId=' + data.teamId;
  }

  onUpdatePlayers(data) {
    this.setState({players: data.players});
  }

  onGameMetadataLoad(data) {
    this.setState({joinGameId: data.joinGameId});
  }

  onLeaderUpdate(data) {
    console.log(data);
    this.setState({isLeader: data.isLeader});
  }

  onNameChange(event) {
    this.setState({ playerName: event.target.value });
    this.clientSocket.emit('update_player_name', {playerName: event.target.value});
  }

  startGame() {
    this.clientSocket.emit('start', {});
  }

  renderPlayers() {
    const players = [];
    for (const [index, value] of this.state.players.entries()) {
      players.push(
        <div>
          { value.playerName + '\u00A0'}
        </div>
      );
    }
    return players;
  }

  render() {
    return (
      <div>
        <h1>Capture the flag.</h1>
        <Form>
          <Form.Group>
            <Form.Label>Your Name:</Form.Label>
            <Form.Control type="text" onChange={this.onNameChange} value={this.state.playerName} />
          </Form.Group>
        </Form>
        <br/>
        <h2>All Players:</h2>
        { this.renderPlayers() }
        <br/>
        <p>Other players can join this game using this code: <b>{this.state.joinGameId}</b></p>
        <h2>How to Play</h2>
        <p>Use the WASD keys to move. Click to shoot. Press "R" to reload. Press "F" to pickup a gun. Bring the enemy flag back to your base or eliminate all your enemies to win.</p>
        <br/>
        {
          this.state.isLeader ? <Button onClick={this.startGame}>Start</Button>
            : <div>Waiting for organizer to start game...</div>
        }
      </div>
    );
  }
}

export default WaitingRoom;
