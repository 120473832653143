import * as constants from './Constants.js';
import io from 'socket.io-client';

import React from 'react';
import Alert from 'react-bootstrap/Alert'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';

class JoinWaitingRoom extends React.Component {
  constructor(props) {
    super(props);

    this.state = {showAlert: false, alertMessage: ''};

    this.joinGameCode = '';

    this.onJoinGameCodeChange = this.onJoinGameCodeChange.bind(this);
    this.onJoinButtonClick = this.onJoinButtonClick.bind(this);
  }

  onJoinGameCodeChange(event) {
    this.joinGameCode = event.target.value;

  }

  onJoinButtonClick() {
    fetch(constants.SOCKET_ADDRESS + '/api/waitingroom/exists?joinGameId=' + this.joinGameCode, {
      method: 'GET',
      crossDomain: true
    }).then(response => response.json())
    .then(response => {
      console.log(response);
      if (response.exists) {
        window.location.href = '/waitingroom?joinGameId=' + this.joinGameCode;
      } else {
        this.setState({showAlert: true, alertMessage: 'Invalid join code.'})
      }
    });

  }

  render() {
    return (
      <div>
        <h1>Capture the flag.</h1>
        <br/>
        <Form>
          <Form.Group>
            <Form.Label>Enter code to join game:</Form.Label>
            <Form.Control type="text" onChange={this.onJoinGameCodeChange} />
          </Form.Group>
        </Form>
        <Alert show={this.state.showAlert} variant='danger'>{this.state.alertMessage}</Alert>
        <Button onClick={this.onJoinButtonClick}>Join</Button>
      </div>
    );
  }
}

export default JoinWaitingRoom;
