import * as constants from './Constants.js';
import io from 'socket.io-client';

import React from 'react';
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';

class Home extends React.Component {
  constructor(props) {
    super(props);

    this.createGame = this.createGame.bind(this);
    this.joinGame = this.joinGame.bind(this);
  }

  createGame() {
    window.location.href = '/waitingroom';
  }

  joinGame() {
    window.location.href = '/joinroom';
  }

  render() {
    return (
      <div>
        <Row className="text-center" style={{'text-align':'center', display: 'block'}}>
          <h1>Capture the flag.</h1>
          <br/>
          <img src={process.env.PUBLIC_URL + '/gameplay.png'} />
        </Row>
        <br/>
        <Row className="text-center" style={{'text-align':'center', display: 'block'}}>
          <Button style={{width: '50%'}} onClick={this.createGame}>Create Game</Button>
        </Row>
        <br/>
        <Row className="text-center" style={{'text-align':'center', display: 'block'}}>
          <Button style={{width: '50%'}} onClick={this.joinGame}>Join Game</Button>
        </Row>
      </div>
    );
  }
}

export default Home;
